import React from "react";

const FeatureCard = ({ image, title, description, reverseBorder = false }) => {
  const cdnBaseUrl = "https://cdn.karsho.com/product_images/"; // Replace with your actual CDN folder for feature images

  return (
    <div
      style={{
        textAlign: "center",
        borderTopRightRadius: reverseBorder ? "15px" : "0", // Conditional border radius
        borderBottomLeftRadius: reverseBorder ? "15px" : "0",
        borderTopLeftRadius: reverseBorder ? "0" : "15px",
        borderBottomRightRadius: reverseBorder ? "0" : "15px",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)", // Add depth with shadow
        border: "1px solid rgba(255, 255, 255, 0.2)", // Subtle border
        background: "rgba(0, 0, 0, 0.5)", // Glass-like semi-transparent black
        backdropFilter: "blur(10px)", // Adds blur effect
        WebkitBackdropFilter: "blur(10px)", // Safari support
        color: "#eaeaea", // Light text color
        padding: "15px",
      }}
    >
      <img
        src={`${cdnBaseUrl}${image}`} // Use CDN URL for the image source
        alt={title}
        style={{
          maxWidth: "100%",
          borderRadius: "10px",
          verticalAlign: "bottom",
          borderTopRightRadius: reverseBorder ? "10px" : "0",
          borderBottomLeftRadius: reverseBorder ? "10px" : "0",
          borderTopLeftRadius: reverseBorder ? "0" : "10px",
          borderBottomRightRadius: reverseBorder ? "0" : "10px",
        }}
      />
      {title && (
        <h4
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            marginBottom: "10px",
            marginTop: "10px", // Space between the image and title
          }}
        >
          {title}
        </h4>
      )}
      {description && <p style={{ color: "gray" }}>{description}</p>}
    </div>
  );
};

export default FeatureCard;
