// getProductList.js
import compact from "./products/scp8c.json";
import standard from "./products/scp8e.json";
import universal from "./products/scp8u.json";

/**
 * Returns a list of all products.
 * @returns {Array} List of product objects.
 */
export const getProductList = () => {
  return [universal, compact, standard];
};
