import React from "react";
import ProductCard from "./ProductCard";
import FAQ from "./FAQ";
import Footer from "./Footer";
import Features from "./Features";
import HeroSection from "./HeroSection";
import CountdownTimer from "./CountdownTimer"; // Import the Navbar
import Navbar from "./Navbar"; // Import the Navbar
import { getProductList } from "./getProductList"; // Import the helper function
import { blackFridayTargetDate } from "./constants";

const productList = getProductList(); // Fetch the product list using the helper function


const App = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "black", // Background for visibility
        color: "white",
        display: "flex",
        flexDirection: "column", // Stack hero and products vertically
        alignItems: "center",
        padding: "0px 0px",
        gap: "40px", // Space between hero and product rows
      }}
    >
      <CountdownTimer targetDate={blackFridayTargetDate} text="BLACK WEEKEND SALE ENDS IN"/>
      {/* Navbar */}
      <Navbar activeItem="VK-SPEC Pods" />

      {/* Hero Section */}
      <HeroSection
        title="VK-SPEC PODS"
        subtitle="Premium gauge pods for every driver’s cockpit."
        backgroundImage="scp8gif.GIF" // Replace with your image URL
      />

      {/* Product Cards Row */}
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          flexWrap: "wrap", // Make cards wrap for smaller screens
          width: "100%",
          maxWidth: "900px", // Keep hero section within a reasonable width
        }}
      >
        {productList.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <Features />
      <div
  style={{
    display: "flex",
    justifyContent: "center",
  }}
>
  <img
    src="https://cdn.karsho.com/product_images/scp8gif.GIF" // Path to the GIF
    alt="Choose Your Spec Animation"
    style={{
      maxWidth: "100%",
      height: "auto",
      borderRadius: "10px", // Optional styling
    }}
  />
</div>
      {/* Product Cards Row */}
      <h4
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          marginBottom: "0px",
        }}
      >
        Choose Your Spec
      </h4>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          flexWrap: "wrap", // Make cards wrap for smaller screens
          width: "100%",
          maxWidth: "900px", // Keep hero section within a reasonable width
        }}
      >
        {productList.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <FAQ />
      <Footer/>
    </div>
  );
};

export default App;
