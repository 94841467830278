import React, { useState, useEffect } from "react";
import { getCart, clearCart, updateCartItem, removeCartItem, findSkuDetails } from "./cartUtils";
import { IoCart, IoTrashBin, IoArrowForward, IoClose } from "react-icons/io5";

const CartPopup = ({ isOpen, onClose }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    // Initialize cart from cookies when the popup is opened
    if (isOpen) {
      setCart(getCart());
    }
  }, [isOpen]);

  const handleQuantityChange = (productId, skuId, quantity) => {
    const itemDetails = findSkuDetails(productId, skuId);
    if (!itemDetails) return;
  
    const availableStock = itemDetails.stock;
  
    if (quantity > availableStock) {
      alert(`Only ${availableStock} items available in stock.`);
      return;
    }
  
    updateCartItem(productId, skuId, quantity);
    setCart(getCart()); // Refresh cart state to reflect changes
  };

  const handleRemoveItem = (productId, skuId) => {
    removeCartItem(productId, skuId);
    setCart(getCart()); // Refresh cart state after removal
  };

  const handleClearCart = () => {
    clearCart();
    setCart([]); // Clear the cart state directly
  };

  const calculateTotal = () =>
    cart.reduce((total, item) => total + item.quantity * item.price, 0);

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        padding: "20px",
      }}
      onClick={onClose} // Close when background is clicked
    >
      {/* Prevent click propagation to the background */}
      <div
        style={{
          backgroundColor: "#1c1c1c",
          borderRadius: "10px",
          width: "90%",
          maxWidth: "600px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
          overflow: "visible",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            padding: "20px",
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: 0, display: "flex", alignItems: "center", gap: "10px" }}>
            <IoCart /> Your Cart
          </h2>
          {cart.length > 0 && (
            <button
              onClick={handleClearCart}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "5px 10px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "20px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <IoTrashBin /> Clear Cart
            </button>
          )}
        </div>
        <div
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          {cart.length > 0 ? (
            cart.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 20px",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                  alignItems: "center",
                }}
              >
                <img
                  src={item.image}
                  alt={item.name}
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "5px",
                    marginRight: "15px",
                  }}
                />
                <div style={{ flex: 1 }}>
                  <h4 style={{ margin: 0 }}>{item.name}</h4>
                  <p style={{ margin: "5px 0", fontSize: "0.9rem" }}>
                    SKU: {item.sku}
                  </p>
                  <p style={{ margin: 0, fontSize: "0.9rem" }}>
                    Quantity:
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) =>
                        handleQuantityChange(
                          item.productId,
                          item.sku,
                          parseInt(e.target.value) || 0
                        )
                      }
                      style={{
                        width: "50px",
                        marginLeft: "10px",
                        textAlign: "center",
                      }}
                    />
                  </p>
                </div>
                <div style={{ textAlign: "right" }}>
                  <p style={{ fontWeight: "bold", margin: "5px 0" }}>
                    ${(item.price * item.quantity).toFixed(2)}
                  </p>
                  <button
                    onClick={() => handleRemoveItem(item.productId, item.sku)}
                    style={{
                      padding: "5px",
                      backgroundColor: "transparent",
                      color: "red",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                textAlign: "center",
                padding: "20px",
                fontSize: "1.1rem",
              }}
            >
              Your cart is empty.
            </p>
          )}
        </div>
        {cart.length > 0 && (
          <div
            style={{
              padding: "20px",
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              Total: ${calculateTotal().toFixed(2)}
            </p>
            <button
              onClick={() => {
                window.location.href = "/checkout";
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "10px 20px",
                backgroundColor: "#000",
                color: "white",
                border: "none",
                borderRadius: "20px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              <IoArrowForward /> Proceed to Checkout
            </button>
          </div>
        )}
        <button
  onClick={onClose}
  style={{
    position: "absolute",
    top: "-35px", // Position above the parent
    right: "-35px", // Position outside to the right of the parent
    padding: "10px",
    width: "40px", // Fixed width
    height: "40px", // Fixed height to match width
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "white",
    border: "none",
    borderRadius: "50%",
    backdropFilter: "blur(10px)",
    fontSize: "20px",
    cursor: "pointer",
    zIndex: 1, // Ensure it's on top of the popup
  }}
>
  <IoClose />
</button>

      </div>
    </div>
  );
};

export default CartPopup;
