import React, { useState, useEffect } from "react";
import { getCart } from "./cartUtils"; // Import cart utility
import CartPopup from "./CartPopup"; // Import the CartPopup component
import { IoCart } from "react-icons/io5";

const Navbar = ({ activeItem = "VK-SPEC Pods" }) => {
  const [cartCount, setCartCount] = useState(0);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isFlashing, setFlashing] = useState(false); // State to trigger flash effect
  const hideCart = true;

  useEffect(() => {
    const updateCartCount = () => {
      const cart = getCart();
      const count = cart.reduce((total, item) => total + item.quantity, 0);

      // Trigger flash effect when cart count changes
      if (count !== cartCount) {
        setFlashing(true);
        setTimeout(() => setFlashing(false), 100); // Remove flash after 1 second
      }

      setCartCount(count);
    };

    updateCartCount();

    // Update the cart count whenever storage is modified
    const interval = setInterval(updateCartCount, 500);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [cartCount]);

  return (
    <>
      <nav
        style={{
          position: "sticky",
          top: 0,
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          color: "white",
          zIndex: 100,
        }}
      >
        {/* Logo */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            src="/logo.svg"
            alt="VK-SPEC"
            style={{ height: "40px", cursor: "pointer" }}
          />
        </div>

        {/* Navigation Items */}
        <div style={{ display: "flex", gap: "20px" }}>
          <a
            href="#features"
            style={{
              padding: "5px 10px",
              fontSize: "16px",
              color: "white",
              textDecoration: "none",
              fontWeight: activeItem === "VK-SPEC Pods" ? "bold" : "normal",
              border:
                activeItem === "VK-SPEC Pods" ? "1px solid white" : "none",
              borderRadius: "50px",
              backgroundColor:
                activeItem === "VK-SPEC Pods"
                  ? "rgba(255, 255, 255, 0.1)"
                  : "transparent",
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
          >
            VK-SPEC Pods
          </a>

          {/* Cart Button (Shown Only When Cart is Not Empty) */}
          {cartCount > 0 && !hideCart && (
            <button
              onClick={() => setCartOpen(true)} // Open cart popup
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                padding: "5px 10px",
                backgroundColor: isFlashing ? "white" : "transparent", // Flash effect
                border: "1px solid white",
                borderRadius: "50px",
                color: "white",
                cursor: "pointer",
                fontWeight: "bold",
                transition: "background-color .2s ease-in-out", // Smooth fade effect
              }}
            >
              <IoCart size={20} />
              <span style={{ fontSize: "0.9rem" }}>{cartCount}</span>
            </button>
          )}
        </div>
      </nav>

      {/* Cart Popup */}
      <CartPopup isOpen={isCartOpen} onClose={() => setCartOpen(false)} />
    </>
  );
};

export default Navbar;
