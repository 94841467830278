import React from "react";

const HeroSection = ({ title, subtitle, backgroundImage }) => {
  const cdnBaseUrl = "https://cdn.karsho.com/product_images/"; // Replace with your actual CDN folder for hero images

  return (
    <div
      style={{
        width: "100%",
        height: "300px", // Adjust height based on your preference
        background: `url("${cdnBaseUrl}${backgroundImage}") center center / cover no-repeat`, // Use CDN URL for the background image
        borderRadius: "0px 0px 0px 0px",
        position: "relative",
        overflow: "hidden",
        marginTop: -40,
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)", // Add shadow for emphasis
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.4)", // Slight overlay for contrast
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>{title}</h1>
        <p style={{ fontSize: "1.2rem", marginTop: "10px" }}>{subtitle}</p>
      </div>
    </div>
  );
};

export default HeroSection;
