import React, { useState } from "react";

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqData = [
    {
      question: "Will it fit my vehicle?",
      answer: "Our design ensures perfect fitment for the following models: 2019-2025 Mazda 3 (4th Generation), 2008-2023 Dodge Charger/Challenger (3rd Generation). Fitment of the Compact and Standard Pods may not be 100% on other vehicles or year models, however the Universal Pod is designed specifically for mounting anywhere in any vehicle."
    },
    {
      question: "How do I install the pods?",
      answer: "VK-SPEC Pods attach to the steering column shroud using screws or Gorilla double-sided mounting tape."
    },
    {
      question: "Is the gauge included?",
      answer: "No, you are not getting a full gauge setup. You are only getting the VK-SPEC Pod to fit any 52mm guage of your choice. P.S. We are working on a full guage setup."
    },
    {
      question: "When will I get it?",
      answer: "We will ship this as soon as possible using a tracked shipping service.",
    },
    {
      question: "What will I get?",
      answer: "You'll receive your VK-SPEC Pod(s), mounting hardware, and possibly a few VK stickers, all neatly packed in protective packaging. No unnecessary extras—just what you need.",
    },
    {
      question: "Can I return it?",
      answer:
        "Yes, you can return the item within 14 days of delivery if it’s in its original condition. Whether the quality or fitment doesn’t meet your expectations, or you’re unhappy with the placement, we’ll accept the return. Please note that return shipping costs are your responsibility.",
    },
  ];

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div id="faq" style={{ padding: "20px 20px 40px 20px", textAlign: "center" }}>
      <h2 style={{ fontSize: "2rem", fontWeight: "bold", marginBottom: "10px" }}>FAQ</h2>
      <h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "20px" }}>
        Here are the answers to the most frequently asked questions
      </h3>
      <p style={{ marginBottom: "40px", color: "gray" }}>
        No convoluted T&Cs. Our FAQ section is our T&C.
      </p>
      <div style={{ maxWidth: "600px", margin: "0 auto", textAlign: "left" }}>
        {faqData.map((item, index) => (
          <div
            key={index}
            style={{
                border: "1px solid rgba(255, 255, 255, 0.2)", // Subtle border for the glass effect
                borderRadius: "10px",
              marginBottom: "10px",
              overflow: "hidden",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              onClick={() => toggleExpand(index)}
              style={{
                padding: "15px 20px",
                backgroundColor: "transparent",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "1rem", fontWeight: "bold" }}>{item.question}</span>
              <span style={{ fontSize: "1.5rem", transform: expandedIndex === index ? "rotate(180deg)" : "rotate(0)", transition: "transform 0.2s" }}>
                &#x25B4; {/* Upward arrow */}
              </span>
            </div>
            {expandedIndex === index && (
              <div style={{ padding: "15px 20px", backgroundColor: "#fff", color: "gray" }}>
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
