import React, { useState, useRef, useEffect } from "react";

const Lightbox = ({ photos, selectedPhoto, onClose }) => {
  const [selectedImage, setSelectedImage] = useState(selectedPhoto || photos[0]);
  const thumbnailRefs = useRef([]);

  useEffect(() => {
    // Disable scrolling when lightbox is open
    document.body.style.overflow = "hidden";

    return () => {
      // Re-enable scrolling when lightbox is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleThumbnailClick = (photo, index) => {
    setSelectedImage(photo);
    // Scroll the selected thumbnail into view
    thumbnailRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  return (
    <>
      {/* Transparent Overlay */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 999,
        }}
        onClick={onClose}
      />

      {/* Lightbox Content */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            padding: "10px 15px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            color: "#000",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
        >
          Close
        </button>

        {/* Full-Size Image */}
        <div
          style={{
            marginBottom: "20px",
            maxWidth: "90%",
            maxHeight: "70vh",
          }}
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "10px",
            }}
          />
        </div>

        {/* Horizontal Thumbnail Scroller */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            overflowX: "auto",
            maxWidth: "90%",
            padding: "10px",
            scrollbarWidth: "thin", // Firefox scrollbar
            scrollbarColor: "rgba(255, 255, 255, 0.5) transparent",
          }}
        >
          {photos.map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`Thumbnail ${index + 1}`}
              ref={(el) => (thumbnailRefs.current[index] = el)} // Save ref for scrolling
              onClick={() => handleThumbnailClick(photo, index)} // Center thumbnail on click
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "5px",
                cursor: "pointer",
                border: selectedImage === photo ? "2px solid white" : "none",
                transition: "border 0.3s",
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Lightbox;
