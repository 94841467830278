import React, { useState } from "react";
import FeatureCard from "./FeatureCard";
import { getProductList } from "./getProductList"; // Import the helper function
import LightboxGrid from "./LightboxGrid";

const productList = getProductList(); // Fetch the product list using the helper function

const Features = () => {
  const [isLightboxOpen, setLightboxOpen] = useState(false); // Lightbox state
  // Collect all unique images from productList
  const images = Array.from(
    new Set(
      productList.flatMap((product) => product.productPhotos) // Flatten all images from products
    )
  );

  return (
    <div id="features" style={{ padding: "20px 20px", maxWidth: "900px" }}>
      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        MOUNT YOUR GAUGES WITH STYLE
      </h2>
      <p style={{ fontSize: "1rem", marginBottom: "40px" }}>
        Expertly designed with durability and precision in mind, VK-SPEC Pods
        integrate smoothly into your vehicle's steering column, offering
        a professional-grade solution for gauge mounting.
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
        }}
      >
        <FeatureCard
          image="scp8-skus5.jpg"
          title="Durable Design"
          description="Manufactured with high-quality PETG and composites to maintain stability and provide long-lasting use."
        />
        <FeatureCard
          image="scp8-skus2.jpg"
          title="OEM+ Fitment"
          description="Designed to sit perfectly around your steering wheel base for a factory-like appearance."
          reverseBorder
        />
        <FeatureCard
          image="scp8-skus8.jpg"
          title="Customizable Options"
          description="Available in multiple configurations to suit your specific gauge needs and preferences."
        />
        <FeatureCard
          image="scp8c-right3.jpg"
          title="Easy Installation"
          description="Quickly install the pods with minimal tools required—ready to use in minutes."
          reverseBorder
        />
        <FeatureCard image="scp8c-3.jpg" />
        <FeatureCard image="scp8-skus.jpg" reverseBorder />
      </div>
      {/* Full-Width Button */}
      <button
        onClick={() => setLightboxOpen(true)} // Open the lightbox
        style={{
          marginTop: "20px",
          width: "100%",
          padding: "15px",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#fff",
          backgroundColor: "#a855f7", // Purple button
          border: "none",
          borderRadius: "0px 0px 20px 0px",
          cursor: "pointer",
          transition: "background-color 0.2s ease",
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#9333ea")} // Hover effect
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#a855f7")}
      >
        See More Product Photos
      </button>

      {/* Lightbox Component */}
      {isLightboxOpen && (
        <LightboxGrid
          photos={images.map(
            (image) => `https://cdn.karsho.com/product_images/${image}` // Use CDN URL for images
          )}
          onClose={() => setLightboxOpen(false)} // Close the lightbox
        />
      )}
    </div>
  );
};

export default Features;
