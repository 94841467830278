import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        padding: "20px 40px",
        backgroundColor: "#000",
        textAlign: "center",
        borderTop: "1px solid rgba(255, 255, 255, 0.2)",
        width: "100%", // Ensures full width
        boxSizing: "border-box", // Includes padding in the width
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "900px", // This restricts the inner content
          margin: "0 auto", // Centers the inner content
          flexWrap: "wrap",
        }}
      >
        {/* Left Section */}
        <div style={{ flex: 1, textAlign: "left", marginBottom: "20px" }}>
          <h4 style={{ fontSize: "1rem", fontWeight: "bold" }}>VIVID KONCEPTS</h4>
          <p style={{ fontSize: "0.9rem", color: "gray" }}>© 2024. Vivid Koncepts Design Studio, LLC. All rights reserved.</p>
        </div>

        {/* Middle Section */}
        <div style={{ flex: 1, textAlign: "center", marginBottom: "20px" }}>
          <h4 style={{ fontSize: "1rem", fontWeight: "bold" }}>Explore</h4>
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            <li>
              <a href="#features" style={{ textDecoration: "none", color: "#fff", fontSize: "0.9rem" }}>
                Features
              </a>
            </li>
            <li>
              <a href="#faq" style={{ textDecoration: "none", color: "#fff", fontSize: "0.9rem" }}>
                FAQ
              </a>
            </li>
          </ul>
        </div>

        {/* Right Section */}
        <div style={{ flex: 1, textAlign: "right", marginBottom: "20px" }}>
          <h4 style={{ fontSize: "1rem", fontWeight: "bold" }}>Contact</h4>
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            <li>
              <a
                href="mailto:vividkonceptsdesign@gmail.com"
                style={{ textDecoration: "none", color: "#fff", fontSize: "0.9rem" }}
              >
                Support
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/vivid.koncepts"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#fff", fontSize: "0.9rem" }}
              >
                Instagram @vivid.koncepts
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
