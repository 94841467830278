import React, { useState } from "react";
import Lightbox from "./Lightbox"; // Import the Lightbox component
import { addToCart } from "./cartUtils";

const ProductCard = ({ product, hideAddToCart = true }) => {
  const [selectedSku, setSelectedSku] = useState(product.skus[0]);
  const [isLightboxOpen, setLightboxOpen] = useState(false);

  const cdnBaseUrl = "https://cdn.karsho.com/product_images/"; // Base URL for the CDN

  const handleSkuChange = (skuId) => {
    const sku = product.skus.find((s) => s.id === skuId);
    setSelectedSku(sku);
  };

  const handleCheckout = () => {
    // Redirect the user to the appropriate Stripe Payment Link
    const paymentLink = selectedSku.onSale
      ? selectedSku.salePaymentLink
      : selectedSku.stripePaymentLink;
    window.location.href = paymentLink;
  };

  // Determine price, shipping info, and stock based on SKU or defaults
  const price = selectedSku.price || product.defaultPrice;
  const salePrice = selectedSku.onSale ? selectedSku.salePrice : null;
  const shippingInfo =
    selectedSku.stock > 2 && selectedSku.madeOnOrder
      ? product.defaultShippingInfo
      : selectedSku.shippingInfo || product.defaultShippingInfo;
  const stock = selectedSku.stock ?? product.defaultStock; // Stock is optional in both SKU and product
  const madeOnOrder = selectedSku.madeOnOrder || false;

  // Determine stock status
  const stockText =
    madeOnOrder && stock < 3
      ? "Made on Order"
      : stock === 0 || stock == null
      ? "Out of Stock"
      : "In Stock";
  const isOutOfStock = !madeOnOrder && (stock === 0 || stock == null);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: "250px",
          borderRadius: "15px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
          padding: "15px",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          color: "#eaeaea",
          fontFamily: "Arial, sans-serif",
          textAlign: "center",
          position: "relative",
        }}
      >
        {/* Product Image */}
        <div
          style={{
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => setLightboxOpen(true)} // Open lightbox on image click
        >
          <img
            src={`${cdnBaseUrl}${selectedSku.image}`} // Use CDN URL for the image
            alt={product.title}
            style={{
              width: "100%",
              aspectRatio: "1 / 1",
              objectFit: "cover",
              borderRadius: "10px",
              verticalAlign: "bottom",
            }}
          />
          {/* New Release Badge */}
          {product.isNewRelease && (
            <div
              style={{
                position: "absolute",
                top: "2px",
                left: "2px",
                backgroundColor: "rgba(144, 238, 144, 1)",
                color: "#004400",
                padding: "5px 10px",
                fontSize: "12px",
                borderRadius: "8px 0px 8px 0px",
                fontWeight: "bold",
                zIndex: 2,
              }}
            >
              New Release
            </div>
          )}
          {/* Sale Badge */}
          <div
            style={{
              position: "absolute",
              top: "2px",
              right: "2px",
              backgroundColor: "#ff4747",
              color: "#fff",
              padding: "5px 10px",
              fontSize: "12px",
              borderRadius: "0px 8px 0px 8px",
              fontWeight: "bold",
              zIndex: 2,
            }}
          >
            Sale
          </div>
          {/* Designed For Badge */}
          {product.designedFor && (
            <div
              style={{
                position: "absolute",
                bottom: "2px",
                left: "2px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "#fff",
                padding: "5px 10px",
                fontSize: "12px",
                borderRadius: "0px 10px 0px 9px",
                backdropFilter: "blur(5px)",
                WebkitBackdropFilter: "blur(5px)",
              }}
            >
              {product.designedFor}
            </div>
          )}
        </div>

        {/* Product Title */}
        <h3 style={{ fontSize: "18px", margin: "10px 0" }}>{product.title}</h3>

        {/* Stock Info */}
        <p
          style={{
            fontSize: "14px",
            color: madeOnOrder && stock < 3
              ? "orange"
              : stock === 0 || stock == null
              ? "red"
              : "lightgreen",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          {stockText}
        </p>

        {/* Shipping Info */}
        <p
          style={{
            fontSize: "14px",
            color: "rgba(255, 255, 255, 0.8)",
            margin: "5px 0",
          }}
        >
          {shippingInfo}
        </p>

        {/* Price */}
        <p style={{ fontSize: "18px", fontWeight: "bold", color: "#fff" }}>
          {salePrice ? (
            <>
              <span
                style={{
                  textDecoration: "line-through",
                  color: "rgba(255, 255, 255, 0.6)",
                  fontSize: "16px",
                  marginRight: "8px",
                }}
              >
                ${price}
              </span>
              <span style={{ color: "#f0c14b" }}>${salePrice}</span>
            </>
          ) : (
            `$${price}`
          )}
        </p>

        {/* SKU Selector */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "5px",
            marginTop: "10px",
            marginBottom: "10px",
            flexWrap: "wrap",
            height: "50px",
            alignItems: "center",
          }}
        >
          {product.skus.map((sku) => (
            <button
              key={sku.id}
              onClick={() => handleSkuChange(sku.id)}
              style={{
                padding: "5px 10px",
                fontSize: "12px",
                color: selectedSku.id === sku.id ? "#000" : "#eaeaea",
                backgroundColor:
                  selectedSku.id === sku.id ? "#f0f0f0" : "transparent",
                border:
                  selectedSku.id === sku.id
                    ? "1px solid #f0f0f0"
                    : "1px solid rgba(255, 255, 255, 0.3)",
                borderRadius: "50px",
                cursor: "pointer",
                transition: "background-color 0.3s, color 0.3s",
                whiteSpace: "nowrap",
              }}
            >
              {sku.label}
            </button>
          ))}
        </div>

        {/* Sticky Buttons */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          {!hideAddToCart && (
            <button
              disabled={isOutOfStock && !madeOnOrder} // Disable if out of stock and not made on order
              onClick={() => addToCart(product.id, selectedSku.id, 1)}
              style={{
                width: "100%",
                padding: "12px",
                fontSize: "14px",
                backgroundColor:
                  isOutOfStock && !madeOnOrder
                    ? "rgba(255, 255, 255, 0.2)"
                    : "rgba(255, 255, 255, 0.1)",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                borderRadius: "5px",
                cursor: isOutOfStock && !madeOnOrder ? "not-allowed" : "pointer",
                color:
                  isOutOfStock && !madeOnOrder
                    ? "rgba(255, 255, 255, 0.4)"
                    : "#eaeaea",
                fontWeight: "bold",
              }}
            >
              Add To Cart
            </button>
          )}

          <button
            disabled={isOutOfStock && !madeOnOrder} // Disable if out of stock and not made on order
            onClick={handleCheckout}
            style={{
              width: "100%",
              padding: "12px",
              fontSize: "14px",
              backgroundColor:
                isOutOfStock && !madeOnOrder
                  ? "rgba(168, 85, 247, 0.4)"
                  : "rgba(168, 85, 247, 0.8)",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: isOutOfStock && !madeOnOrder ? "not-allowed" : "pointer",
              fontWeight: "bold",
            }}
          >
            Buy Now
          </button>
        </div>
      </div>

      {/* Render Lightbox */}
      {isLightboxOpen && (
        <Lightbox
          photos={product.productPhotos.map(
            (photo) => `${cdnBaseUrl}${photo}` // Use CDN URL for lightbox photos
          )}
          onClose={() => setLightboxOpen(false)} // Close lightbox
        />
      )}
    </>
  );
};

export default ProductCard;
