import React, { useState } from "react";
import Lightbox from "./Lightbox"; // Assuming you already have a Lightbox component

const LightboxGrid = ({ photos, onClose }) => {
  const [selectedImage, setSelectedImage] = useState(null); // State for the selected image

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        overflow: "hidden", // Prevent outer body scrolling
      }}
    >
      {/* Close Button */}
      <button
        onClick={onClose} // Close lightbox grid
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          padding: "10px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          color: "#000",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        Close
      </button>

      {/* Scrollable Grid Content */}
      <div
        style={{
          display: "grid",
          gap: "20px",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))", // Flexible grid
          justifyContent: "center", // Center grid in the container
          alignItems: "center",
          maxHeight: "90vh", // Limit the height to ensure it's scrollable
          overflowY: "auto", // Enable vertical scrolling
          padding: "20px",
          width: "100%", // Full width for grid
        }}
      >
        {photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Product ${index + 1}`}
            style={{
              width: "100%", // Take full width of the grid cell
              maxHeight: "300px", // Limit image height
              objectFit: "cover",
              borderRadius: "10px",
              cursor: "pointer", // Make it clear the image is clickable
            }}
            onClick={() => setSelectedImage(photo)} // Set the selected image
          />
        ))}
      </div>

      {/* Regular Lightbox */}
      {selectedImage && (
        <Lightbox
          photos={photos} // Pass all photos to the Lightbox
          selectedPhoto={selectedImage} // Pass the selected photo to Lightbox
          onClose={() => setSelectedImage(null)} // Close the Lightbox
        />
      )}
    </div>
  );
};

export default LightboxGrid;
