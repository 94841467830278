import React, { useEffect, useState } from "react";

const CountdownTimer = ({ targetDate, inline = true, text = "LIMITED TIME SALE" }) => {
  const calculateTimeLeft = () => {
    if (!targetDate) return null; // No target date provided
    const difference = +new Date(targetDate) - +new Date();
    if (difference <= 0) return null; // Time's up
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (!targetDate) return; // Skip timer if no target date

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  if (!timeLeft && targetDate) return null; // Do not render if the target date has passed

  return (
    <div
      style={{
        width: "100%",
        padding: "10px 20px",
        marginBottom: "-40px",
        backgroundColor: "#ff4747",
        color: "black",
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "500",
        boxSizing: "border-box",
      }}
    >
      <span>
        {text}
        {timeLeft && (
          <span style={{ marginLeft: "5px", fontWeight: "bold", color: 'black' }}>
            {timeLeft.days > 0 && `${timeLeft.days}d `}
            {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
          </span>
        )}
      </span>
    </div>
  );
};

export default CountdownTimer;
